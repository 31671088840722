import { navigate, NavigateFn, RouteComponentProps, useNavigate } from "@reach/router";
import Page from "@src/libs/ui/Page";
import ConfigStore from "@src/model/config";
import CustomerStore from "@src/model/customer-repo";
import OrderStore from "@src/model/order-repo";
import PaymentMethodStore from "@src/model/paymentmethod-repo";
import ProductStore from "@src/model/product-repo";
import PromoStore from "@src/model/promo-repo";
import UILoading from "@src/ui/Loading";
import CurrentOrder from "@src/ui/order/CurrentOrder";
import ModalCustomerInfo from "@src/ui/order/ModalCustomerInfo";
import ModalSubmitNew from "@src/ui/order/ModalSubmitNew";
import ProductPicker from "@src/ui/order/ProductPicker";
import TopBar from "@src/ui/TopBar";
import ModalTotemList from "@src/ui/totem/ModalTotemList";
import { observer } from "mobx-react";
import React, { Suspense, useEffect } from "react";
const Order: React.FC<RouteComponentProps> = observer((props) => {
  useEffect(() => {
    if(!ConfigStore.isWeb){
      ConfigStore.checkConfigStore()
    }
    ProductStore.reload();
    
    CustomerStore.reload();
    PaymentMethodStore.reload();

    if (!OrderStore.idCurrentOrder) {
      OrderStore.newTempOrder();
    }
  }, []);

  // const navigate : NavigateFn = useNavigate();
  // navigate.


  window.addEventListener('online', function(e) { 
    ProductStore.reload();
    
    CustomerStore.reload();
    PaymentMethodStore.reload();
    PromoStore.reload();
    PaymentMethodStore.reload();
  });

  

  return (
    <Suspense fallback={<UILoading />}>
      <Page
        {...props}
        className="h-full grid grid-cols-12 bg-gray-100 relative"
        style={{
          backgroundColor:"#f7fafc"
        }}
      >
        {{
          default: (
            <>
              <div className="md:col-span-8 xl:col-span-9 h-full overflow-hidden flex flex-col"
              style={{
                backgroundColor:"#f7fafc"
              }}
              >
                <TopBar totem={ConfigStore.selectedTotem}/>
                <ProductPicker currentOrder={OrderStore.currentOrder} />
              </div>
              <CurrentOrder />
              {/* <ModalSubmit /> */}
              <ModalSubmitNew />
              <ModalCustomerInfo />
              <ModalTotemList/>
            </>
          ),
        }}
      </Page>
    </Suspense>
  );
});

export default Order;
