
import scannerTotem   from "@src/assets/images/scanner-totem.png";
import scannerCashier from "@src/assets/images/scanner-cashier.png";

import Modal from "@src/libs/ui/Modal";

import ConfigStore from "@src/model/config";

import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { runInAction } from "mobx";
import OrderStore from "@src/model/order-repo";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import { Device } from "@capacitor/core";
import { AppConfig } from "@src/config/app";
import { XCircle} from "heroicons-react";
import { useNavigate } from "@reach/router";
const PrintBT = require("thermal-printer-cordova-plugin/www/thermal-printer");


const ModalInfo: React.FC = observer(() => {
  
  const meta = useLocalObservable(() => ({
    visibleSugest: false,
    focus: "",
    isMobile: false,
    btIsOn:false,
    listPrinter: [],
  }));
  const cancel = () => {
    
    if(ConfigStore.showModalBarcode==10){
      ConfigStore.showModalBarcode=0
    }
    
  };
  const navigate = useNavigate();
  

  useAsyncEffect(async () => {
    meta.btIsOn = false
    //const info = await Device.getInfo();
    
    if (ConfigStore.isWeb == false) {
      
      // runInAction(() => (meta.isMobile = true));


      await PrintBT.listPrinters(
        { type: "bluetooth" },
        (res: any) => runInAction(() => {
          
          if(res.length>0){
            meta.btIsOn = true
          }
        }),
        (e: any) => console.log("list error: ", e)
      );
    }
  }, [ConfigStore.showModalBarcode]);


  return (
    <Modal visible={ConfigStore.showModalBarcode==10} onDismiss={cancel}
    className="h-90 absolute w-1/2">
      <div className="bg-white p-4 h-full rounded-md">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-1 flex-col">

          <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-1 flex-row">
            <h1
              className="text-xl font-bold leading-6 text-gray-800"
              id="modal-headline"
            >
              Informasi
            </h1>
            <div
            style={{
              flex:1,
              flexGrow:1
            }}
            ></div>
            {/* <button
              onClick={cancel}
            >
              <XCircle size={20} className="text-gray-600" />
            </button> */}
            
          </div>

          
            <div className="mb-4 mt-5">
              <div className="font-bold text-sm">
                Akun
              </div>

              
              <span className="flex w-full rounded-md sm:w-auto">
                <text className="text-sm">
                    {ConfigStore.fullname == "" ?
                    ConfigStore.username
                    :
                    ConfigStore.fullname
                    }
                    
                </text>
                
                <div className="flex flex-grow"/>
                <text className="text-sm capitalize"
                  style={{
                    color:"#808080"
                  }}
                >
                    {ConfigStore.role.charAt(0).toUpperCase() + ConfigStore.role.slice(1)} {"("} {ConfigStore.company.client_name}{")"}
                </text>
              </span>

              <span className="flex w-full rounded-md sm:w-auto">
                <text className="text-sm">
                    Print Template
                </text>
                
                <div className="flex flex-grow"/>
                <text className="text-sm capitalize"
                  style={{
                    color:"#808080"
                  }}
                >
                    {ConfigStore.client.print_template}
                </text>
              </span>

              {/* <span className="flex w-full rounded-md sm:w-auto">
                <text className="text-sm">
                    {ConfigStore.outlet.nama}
                    {ConfigStore.outlet.catatan_struk}
                    
                </text>
                
                <div className="flex flex-grow"/>
                <text className="text-sm capitalize"
                  style={{
                    color:"#808080"
                  }}
                >
                    {ConfigStore.role.charAt(0).toUpperCase() + ConfigStore.role.slice(1)} {"("} {ConfigStore.company.client_name}{")"}
                </text>
              </span> */}

              {/* <div className="font-bold">
                  {ConfigStore.fullname}
              </div>
              <div className="italic">
                  {ConfigStore.role}
              </div> */}
            </div>
            <div className="font-bold text-sm">
                Perangkat Terhubung
            </div>

            {ConfigStore.isWeb == false && <>
            
            <span className="flex w-full rounded-md sm:w-auto">
                <text className="text-sm">
                  Printer Struk
                </text>
                <div className="flex flex-grow"/>
                <div className="flex flex-col  text-sm">
                  <div className="text-right"
                    style={{
                      color:ConfigStore.selectedStrukName!=""  && meta.btIsOn==true ?"#319346":"#808080"
                    }}
                  >
                    {ConfigStore.selectedStrukName!=""  && meta.btIsOn==true ?"Terhubung ("+ConfigStore.selectedStrukPrinters+")":"Tidak Terhubung"}
                  </div>
                    {/* {ConfigStore.selectedStrukPrinters!="" && meta.btIsOn==true &&
                    <div  className="text-sm">
                        {ConfigStore.selectedStrukPrinters!=""  && meta.btIsOn==true ?ConfigStore.selectedStrukPrinters:"Tidak Terhubung"}
                    </div>
                    } */}
                </div>
            </span>
            <span className="flex w-full rounded-md sm:w-auto">
                <text className="text-sm">
                  Printer Label
                </text>
                <div className="flex flex-grow"/>
                <div className="flex flex-col  text-sm">
                  <div className="text-right"
                    style={{
                      color:ConfigStore.selectedLabelName!=""  && meta.btIsOn==true ?"#319346":"#808080"
                    }}>
                            {ConfigStore.selectedLabelName!=""  && meta.btIsOn==true ?"Terhubung ("+ConfigStore.selectedLabelPrinters+")":"Tidak Terhubung"}
                          </div>
                            {/* {ConfigStore.selectedLabelPrinters!="" && meta.btIsOn==true &&
                            <div className="text-sm">
                                {ConfigStore.selectedLabelPrinters!=""  && meta.btIsOn==true ?ConfigStore.selectedLabelPrinters:"Tidak Terhubung"}
                            </div>
                            } */}
                      </div>
            </span>
            </>}
            <span className="flex w-full rounded-md sm:w-auto">
                <text className="text-sm">
                    Totem
                </text>
                <div className="flex flex-grow"/>
                <text className="text-sm capitalize"
                  style={{
                    color:ConfigStore.selectedTotem.name?"#319346":"#808080"
                  }}
                >
                    {ConfigStore.socketIsConnected!==1?"Koneksi Gagal":ConfigStore.selectedTotem.name!==""?ConfigStore.selectedTotem.name:"Tidak Terhubung"}
                </text>
            </span>
            {/* <span className="flex w-full rounded-md sm:w-auto">
                <text className="text-sm">
                    Versi
                </text>
                <div className="flex flex-grow"/>
                <text className="text-sm"
                  style={{
                    color:"#808080"
                  }}
                >
                    v.{AppConfig.appVersion}
                </text>
            </span> */}

            <text className="italic text-xs mt-2">
                  Kelava-POS v.{AppConfig.appVersion}
            </text>

            {/* --------------------------------------------------------------- */}
            {/* <div className="pb-3 mb-3">
              <tr className="pb-3 mb-3">
                  <td className="py-1">
                      Printer Struk
                  </td>
                  <td className="mx-2 px-2 py-1">
                    {" : "}
                  </td>
                  <td  className={`items-end text-right ${ConfigStore.selectedStrukName!="" && meta.btIsOn==true ?"":" italic "}`}>
                      <div className="flex flex-col">
                          <div>
                            {ConfigStore.selectedStrukName!=""  && meta.btIsOn==true ?ConfigStore.selectedStrukName:"Tidak Terhubung"}
                          </div>
                            {ConfigStore.selectedStrukPrinters!="" && meta.btIsOn==true &&
                            <div  className="text-sm">
                                {ConfigStore.selectedStrukPrinters!=""  && meta.btIsOn==true ?ConfigStore.selectedStrukPrinters:"Tidak Terhubung"}
                            </div>
                            }
                      </div>
                      
                  </td>
              </tr>
              <tr className="pb-3 mb-3">
                <td className="mb-2 pb-2">
                    {"    "}
                  </td>
              </tr>
              <tr  className="py-1 my-1">
                  <td>
                      Printer Label
                  </td>
                  <td className="mx-2 px-2">
                    {"  :  "}
                  </td>
                  <td  className={`items-end text-right ${ConfigStore.selectedLabelName!=""  && meta.btIsOn==true ?"":" italic "}`}>
                  <div className="flex flex-col">
                          <div>
                            {ConfigStore.selectedLabelName!=""  && meta.btIsOn==true ?ConfigStore.selectedLabelName:"Tidak Terhubung"}
                          </div>
                            {ConfigStore.selectedLabelPrinters!="" && meta.btIsOn==true &&
                            <div className="text-sm">
                                {ConfigStore.selectedLabelPrinters!=""  && meta.btIsOn==true ?ConfigStore.selectedLabelPrinters:"Tidak Terhubung"}
                            </div>
                            }
                      </div>
                  </td>
              </tr>
              <tr className="pb-3 mb-3">
                <td className="mb-2 pb-2">
                    {"    "}
                  </td>
              </tr>
              <tr className="py-1 my-1">
                  <td>
                      Totem
                  </td>
                  <td className="mx-2 px-2">
                      {" : "}
                  </td>
                  <td  className={`items-end text-right ${ConfigStore.socketIsConnected!==1?" italic ":ConfigStore.selectedTotem.name!==""?"":" italic "}`}>
                    {ConfigStore.socketIsConnected!==1?"Koneksi Gagal":ConfigStore.selectedTotem.name!==""?ConfigStore.selectedTotem.name:"Tidak Terhubung"}
                  </td>
              </tr>
              
            </div> */}
            <div className="col-span-6 bg-gray-50 py-3 sm:flex sm:flex-row  mt-2">
              <span className="flex-1 flex-grow align-text-bottom">
                
              </span>
              {ConfigStore.isWeb == false && <>
                <span className="rounded-md shadow-sm sm:ml-3 mr-1"
                style={{
                  width:100
                }}
                >
                    <button
                      type="button"
                      className="inline-flex  justify-center items-center w-full rounded-md border border-transparent px-6 py-2 border-gray-800 bg-gray-800 leading-6 font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:border-black focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple  flex-1 text-sm"
                      onClick={()=>{
                          if(ConfigStore.showModalBarcode==10){
                            ConfigStore.showModalBarcode=0
                          }
                          let conf = window.confirm("Apakah anda yakin akan keluar?");
                          if(conf){
                            navigate("/login", { replace: true });
                            ConfigStore.logout();
                            runInAction(() => {
                              OrderStore.openProductPicker = false;
                            });
                          }
                          
                        }}
                      // disabled={!currentOrder.canSubmit||OrderStore.isSaving}
                    >
                      Log out
                    </button>
                  </span>
                  </>}
                  <span className="mt-3 rounded-md shadow-sm sm:mt-0 ml-1"
                  style={{
                    width:100
                  }}>
                    <button
                      type="button"
                      className="inline-flex justify-center items-center w-full rounded-md border border-gray-800 px-6 py-2 bg-white leading-6 font-medium text-gray-800 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple flex-1 text-sm"
                      onClick={cancel}
                      style={{
                        borderWidth: 1,
                        borderColor: "gray",
                      }}
                      
                    >
                      Tutup
                    </button>
                  </span>
                </div>
            {/* <div className="italic text-xs mt-5">
                  Kelava-POS v.{AppConfig.appVersion}
            </div> */}
          </div>
        </div>
      </div>
      
    </Modal>
  );
});



export default ModalInfo;
